import React from "react";
import { Workshops } from "./ui/icons/CategoryIcons/Workshops";
import { Camping } from "./ui/icons/CategoryIcons/Camping";
import { Tour } from "./ui/icons/CategoryIcons/Tour";
import { DefaultCategoryIcon } from "./ui/icons/CategoryIcons/DefaultCategoryIcon";
import { Screening } from "./ui/icons/CategoryIcons/Screenings";
import { Festivals } from "./ui/icons/CategoryIcons/Festival";
import { Shopping } from "./ui/icons/CategoryIcons/Shopping";
import { Sports } from "./ui/icons/CategoryIcons/Sports";
import { Courses } from "./ui/icons/CategoryIcons/Courses";
import { Health } from "./ui/icons/CategoryIcons/Health";
import { Music } from "./ui/icons/CategoryIcons/Music";
import { Games } from "./ui/icons/CategoryIcons/Games";
import { Kids } from "./ui/icons/CategoryIcons/Kids";
import { Theatre } from "./ui/icons/CategoryIcons/Theatre";
import { Talks } from "./ui/icons/CategoryIcons/Talks";
import { Comedy } from "./ui/icons/CategoryIcons/Comedy";
import { Volunteer } from "./ui/icons/CategoryIcons/Volunteer";
import { Dance } from "./ui/icons/CategoryIcons/Dance";
import { Art } from "./ui/icons/CategoryIcons/Art";
import { Food } from "./ui/icons/CategoryIcons/Food";
import { WhatsHot } from "./ui/icons/CategoryIcons/WhatsHot";
import { Getaways } from "./ui/icons/CategoryIcons/Getaways";
import { Parties } from "./ui/icons/CategoryIcons/Parties";
import { Learn } from "./ui/icons/CategoryIcons/Learn";
import { Travel } from "./ui/icons/CategoryIcons/Travel";
import { InsiderVibes } from "./ui/icons/CategoryIcons/InsiderVibes";
import { Adventure } from "./ui/icons/CategoryIcons/Adventure";
import { AmusementParks } from "./ui/icons/CategoryIcons/AmusementParks";
import { Activities } from "./ui/icons/CategoryIcons/Activities";
import { Bake } from "./ui/icons/CategoryIcons/Bake";
import { Brunch } from "./ui/icons/CategoryIcons/Brunch";
import { Conference } from "./ui/icons/CategoryIcons/Conference";
import { Cook } from "./ui/icons/CategoryIcons/Cook";
import { Cricket } from "./ui/icons/CategoryIcons/Cricket";
import { Cycling } from "./ui/icons/CategoryIcons/Cycling";
import { Drinks } from "./ui/icons/CategoryIcons/Drinks";
import { Experiences } from "./ui/icons/CategoryIcons/Experiences";
import { Explore } from "./ui/icons/CategoryIcons/Explore";
import { Monuments } from "./ui/icons/CategoryIcons/Monuments";
import { Play } from "./ui/icons/CategoryIcons/Play";
import { Run } from "./ui/icons/CategoryIcons/Run";
import { TrySomethingNew } from "./ui/icons/CategoryIcons/TrySomethingNew";
import { Walks } from "./ui/icons/CategoryIcons/Walks";
import { Dhandia } from "./ui/icons/CategoryIcons/Dhandia";
import { Dinner } from "./ui/icons/CategoryIcons/Dinner";
import { HoliParty } from "./ui/icons/CategoryIcons/HoliParty";
import { HoliTrips } from "./ui/icons/CategoryIcons/HoliTrips";
import { Painting } from "./ui/icons/CategoryIcons/Painting";
import { SummerCamp } from "./ui/icons/CategoryIcons/SummerCamp";
import { MuseumVisit } from "./ui/icons/CategoryIcons/MuseumVisit";
import Christmas from "./ui/icons/CategoryIcons/Christmas";
import NyeGateAways from "./ui/icons/CategoryIcons/NyeGateAways";
import NyeParties from "./ui/icons/CategoryIcons/NyeParties";
import { DurgaPuja } from "./ui/icons/CategoryIcons/DurgaPuja";
const CategoryIconMap = {
    "580476f3fdacc65c25706ae1": Camping,
    "580f84c4773cac4207fa8454": Camping,
    "5694dc9032dbd794459d4999": Travel,
    "5718844ba7c87aa4bf028489": Screening,
    "539ffa22e8883623584e8557": Festivals,
    "5a01659a4d2896dc67de6168": Shopping,
    "57ee2f27a7c89602b50761e7": Sports,
    "5891a5956ff3b85c07ed52f4": Workshops,
    "606aae30f1c0ce0008abc388": Workshops,
    "5e8dc3e44e3da20008c2bf2e": Courses,
    "582568f759ecec4207f2d523": Health,
    "5e8dc3677ea49b00086f3652": Health,
    "57d6897a272b564fc04b24ac": Music,
    "5857c5ea64e63c4707f97d2f": Games,
    "5e940737e2ee3700084386c1": Kids,
    "5495d81fcd6aff85cf32cec2": Theatre,
    "5e8dc3befc32de000857287e": Theatre,
    "5b333f605cacd1180022c3f4": Talks,
    "539ffa08e8883623584e8555": Comedy,
    "5bb59edafc5a50001874beb3": Volunteer,
    "57e8d704a7c8af9573705374": Dance,
    "539ffa38e8883623584e8558": Art,
    "5ee5fdb2fce37c0008cb7989": Art,
    "539fffe6e8883623584e8563": Food,
    "61a5ebd197192f0007ead7d7": WhatsHot,
    "61a5ebe6625d010008416bf8": Getaways,
    "5853aee96cb8b35d07105ba1": Parties,
    "58048844fdacc65c25706aeb": Tour,
    "57d689c8272b564fc04b24b2": Learn,
    "571884cfa7c87aa4bf02848f": Adventure,
    "592d73998a77bd5b07c905ad": AmusementParks,
    "59d661e6d35fa7bc22092205": Activities,
    "5911b8deabe4015907c7a63b": Bake,
    "58048b7ffdacc65c25706aef": Brunch,
    "571884faa7c87aa4bf028493": Conference,
    "57d6899b272b564fc04b24ae": Cook,
    "591985513c3ba35c0701cf3e": Cricket,
    "580f8456773cac4207fa83db": Cycling,
    "589c5f4178bea15d07e5f934": Drinks,
    "588b440f4c92fd47077cae8b": Experiences,
    "59301a22fb6c81030636d32c": Explore,
    "5d78edd4b5e472b85f3a806c": Monuments,
    "5af55734d94e50c0296082a1": Play,
    "5ce29a8fc9f4ba00077120fd": Run,
    "59c895b6ca9c622d4169552c": TrySomethingNew,
    "59b635b6182ff56f2e061c5e": Walks,
    "5805f9fd1a5edceca03fed75": InsiderVibes,
    "669e40ebd06b355867268b69": Dhandia,
    "5854c12c64e63c4707f813a3": Dinner,
    "58b3ddc9388d825c071ab330": HoliParty,
    "5e42b5960c0bbd00082b580f": HoliTrips,
    "5804862ffdacc65c25706ae9": Painting,
    "5ca5e700095676000727769d": SummerCamp,
    "654ccbdf3e7c2c0008481dfd": MuseumVisit,
    "654cb9c68aa6fa0009f65679": Christmas,
    "6549d8d6d333620008745ccb": NyeGateAways,
    "6549d922a18eb9000844da75": NyeParties,
    "66b5e438b91f210536f85aac": DurgaPuja
};
export const getCategoryIcon = (categoryId, width, height, fill, viewBox) => {
    if (CategoryIconMap.hasOwnProperty(categoryId)) {
        const Icon = CategoryIconMap[categoryId];
        return <Icon width={width} height={height} fill={fill} viewBox={viewBox}/>;
    }
    else {
        return <DefaultCategoryIcon width={width} height={height} fill={fill} viewBox={viewBox}/>;
    }
};

import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Ready for this?",
        subtitle: "Gujarat No Navratri",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725065819/kwgbf1hko0fjldmoazuq.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "rangilo-re-utsav-gujarat-no-navratri-2024"
        }
    },
    {
        title: "Radiance Dandiya",
        subtitle: "Jio World Convention",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726283889/vuaghxv2tyhxlohnqiez.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "radiance-dandiya-navratri-2024"
        }
    },
    {
        title: "Navratri at MBCA",
        subtitle: "BKC MMRDA Ground",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284048/dn4o97zscxcpmctvjktb.jpg",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "mbca-dandiya-night-navratri-2024"
        }
    },
    //Delhi
    {
        title: "Mamma Mia!",
        subtitle: "DJ Hugel in the house",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052462/lnagnnw4k7xbgqvboxzj.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "souldout-presents-hugel-live-in-new-delhi-nov10-2024"
        }
    },
    {
        title: "Sonu Nigam, LIVE",
        subtitle: "Get tickets 'Saathiya'",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727698905/e4a5b1zmpovdczil3lrh.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "the-sonu-nigam-live-show-delhi-oct11-2024"
        }
    },
    {
        title: "Indie Sensation",
        subtitle: "Shalmali Kholgade, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052483/iododwxzblwbwapmmyu4.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "this-is-me-india-tour-delhi-2024"
        }
    },
    //Bengaluru
    {
        title: "Sunday Soul Sante",
        subtitle: "Diwali Edit - Oct 27th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052608/zzapfsvjsdua3ur6b1ie.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "sunday-soul-sante-diwali-edition-oct27-2024"
        }
    },
    {
        title: "EDM Party",
        subtitle: "At the Sunburn Union",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699052/tmvzadoc1wnwx4dxt1x6.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "highlighttribe-live-at-sunburn-union-oct12-2024"
        }
    },
    {
        title: "Techno Feast",
        subtitle: "Innellea on Nov 17th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052630/df7924ht4bbxbqwjwaqm.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "innellea-bangalore-nov17-2024"
        }
    },
    //Pune
    {
        title: "Festive Fervour",
        subtitle: "Pankhida Raasgarba",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699381/wyqnbfhl5vrofaw5ecof.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "pankhida-raasgarba-2024-navratri-2024"
        }
    },
    {
        title: "Indie Sensation",
        subtitle: "Shalmali Kholgade, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052849/ebxhmag0rrtyhtyhelqw.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "this-is-me-india-tour-pune-oct18-2024"
        }
    },
    {
        title: "Taste of Bavaria",
        subtitle: "Pune Oktoberfest",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052888/i4qkphckaz1nnjldlngx.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "pune-oktoberfest-2024-nov9-2024"
        }
    },
    //Goa
    {
        title: "No EDM, No Party",
        subtitle: "Raeeth, Goa",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699563/qx8djyzerxqedakh6nxc.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-experience-31-dec-dec31-2024"
        }
    },
    {
        title: "3 day wellness fest",
        subtitle: "In Goa this November",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725066746/pzjvpivvopkn1eyfw4tj.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "bliss-tree-experience-goa-2024-nov7-2024"
        }
    },
    {
        title: "Season Opener",
        subtitle: "Kick off Party Season",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699606/hdyxacoxedyy5atuiixu.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-labryin-season-opening-party-oct26-2024"
        }
    },
    //Hyderabad
    {
        title: "Double Treat",
        subtitle: "Dreamhack x Comic Con",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1725812939/fhsxpk4ikpqisxicfyr8.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    },
    {
        title: "Indie Sensation",
        subtitle: "Shalmali Kholgade, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052849/ebxhmag0rrtyhtyhelqw.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "this-is-me-india-tour-hyderabad-2024"
        }
    },
    {
        title: "No EDM, No Party",
        subtitle: "Xora on Nov 24th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699679/snatgapmwihlpnxfwkcc.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "techno-and-chill-w-rivo-at-xora-nov24-2024"
        }
    },
    //Chennai
    {
        title: "Karthik Raaja",
        subtitle: "LIVE on Oct 19th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726904591/yj4ozrbfn2dhl3l1gey3.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "king-of-kings-2024-karthik-raaja-live-in-concert-coimbatore-oct19-2024"
        }
    },
    {
        title: "Amrit Ramnath",
        subtitle: "On Oct 12th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727793839/uzvcytf9x0pqgmdohugz.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "amrit-ramnath-the-100000-dreams-tour-chennai-2024"
        }
    },
    {
        title: "Food for a cause",
        subtitle: "Moi Virundhu",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053013/ae9ep9okqox1d1vthao0.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "moi-virundhu-a-biriyani-festival-presented-by-help-on-hunger-oct13-2024"
        }
    },
    //Kolkata
    {
        title: "Ek Mulaqaat",
        subtitle: "Vishal Mishra, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053058/j5v9kq1wtkxu7rk3ocm7.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "vishal-mishra-kolkata-oct19-2024"
        }
    },
    {
        title: "Mann Bharrya",
        subtitle: "B Praak, LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053082/fch569lkhvr8ggfpecsy.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "kolkata-odyssey-i-b-praak-oct20-2024"
        }
    },
    {
        title: "Falguni Pathak",
        subtitle: "Navratri 2024",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727700548/ubucppn9t2paoxqxfib6.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "falguni-pathak-i-live-navratri-2024"
        }
    },
    //coimbatore
    {
        title: "Vidyasagar, LIVE",
        subtitle: "Greatest hits on Oct 26th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053129/cd2akql1qrmim4xzlkrg.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "vidyasagar-the-greatest-hits-live-bengaluru-oct26-2024"
        }
    },
    {
        title: "Yuvan Shankar, LIVE!",
        subtitle: "Coimbatore on Oct 12th",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726904780/u2nu70frmt7ambohos8y.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        }
    },
    {
        title: "Karthik Raaja",
        subtitle: "And more legends, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724470597/wh7dqd6bdfkyaleak7go.jpg",
        includeCities: ["Coimbatore"],
        link: {
            resource_type: "event",
            slug: "king-of-kings-2024-karthik-raaja-live-in-concert-coimbatore-oct19-2024"
        }
    },
    //visakhapatnam
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Dreamhack x CominCon",
        subtitle: "Best of both worlds",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726905193/zbpkf3s5t8a2yiwunfmt.jpg",
        includeCities: ["Visakhapatnam"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    },
    //vizag
    {
        title: "Top Lesi Poddi",
        subtitle: "Rockstar DSP, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039096/yn8obun5ohhi2e2albrt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        title: "Chale Aana",
        subtitle: "See Armaan Malik, LIVE!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039123/dmb784vsdxfcypyyvi5i.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    },
    {
        title: "Dreamhack x CominCon",
        subtitle: "Best of both worlds",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1726905193/zbpkf3s5t8a2yiwunfmt.jpg",
        includeCities: ["Vizag"],
        link: {
            resource_type: "event",
            slug: "dreamhack-hyderabad-comic-con-2024-nov15-2024"
        }
    }
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "669e40ebd06b355867268b69",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727698863/dpjqobxcp8j7vle8niir.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727698857/bzkbwqht6eyvzsysf6bx.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "naidu-club-korakendra-navratri-2024-navratri-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052707/xe1evgjoihoviegjqbic.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052702/szcvt64ilbkn0ivbfuay.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "vidyasagar-the-greatest-hits-live-bengaluru-oct26-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1719659204/iuiufed8pkmqtibowphk.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1719659217/m5evxoxrlcsveasuoov2.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "peter-cat-recording-co-good-luck-beta-24-pune-dec14-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052928/aeqpft0vebwm1ubs46s7.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052931/eamrh5zlpfofyofi5gah.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "raeeth-presents-pretty-pink-16-nov-nov16-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722743272/jraxkrnotsxdeplez9bz.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1722743278/xdhxhiigjn1traucrgey.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-hyderabad-oct19-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284935/b8jfds6qqvycuwgjhim8.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284925/fxlfw2llaa9kr6kmt0up.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284935/b8jfds6qqvycuwgjhim8.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284925/fxlfw2llaa9kr6kmt0up.jpg",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        }
    },
    {
        categoryId: "66b5e438b91f210536f85aac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727794480/sf1dazugsrmzlbxsomrm.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727794474/my2e7g76t6bz8ottc8nw.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "durga-pujor-passport-2024-i-forum-for-durgotsab-durga-puja-2024"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039161/wieerk3ojaqv5auxigy6.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724039166/ccvsttnb57ddne4x0bju.jpg",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        }
    }
];
export const showcase = [
    //Type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia fill="white"/>,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["mumbai"],
        cardListType: "small-event-card",
        tag: "navratri2024-mumbai",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-mumbai"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false,
        //lightMode:true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052568/djrcbjtk5zfnbipdahnk.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728052565/owaekox2trjpcpci66of.jpg",
        //categoryIcon: <Theatre />,
        heading: "The Power of Vulnerability",
        subheading: "Kiran Nadar Museum of Art (KNMA) Theatre Festival",
        caption: "KMNA's first theatre festival, featuring explorations in contemporary theatre will take place from Oct 14th to 20th.",
        cta: "Discover more",
        includeCities: ["delhi"],
        cardListType: "small-event-card",
        tag: "kiran-nair-theatre-festival-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "article",
            slug: "kiran-nadar-museum-of-art-theatre-festival"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["bengaluru"],
        cardListType: "small-event-card",
        tag: "navratri2024-bengaluru",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-bengaluru"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["pune"],
        cardListType: "small-event-card",
        tag: "navratri2024-pune",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-pune"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["hyderabad"],
        cardListType: "small-event-card",
        tag: "navratri2024-hyderabad",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-hyderabad"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053264/uhjr6psjj7cxe6dfkanf.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728053261/r3xdxpgmoykrconwgvvg.png",
        //categoryIcon: <DurgaPuja />,
        heading: "PLAN YOUR PERFECT PUJO",
        subheading: "From festive feasts to Pandal hopping, VIP passes & offbeat experiences!",
        caption: "Indulge in divine flavors, explore vibrant pandals, or celebrate tradition with a twist.",
        cta: "Discover more",
        includeCities: ["kolkata"],
        cardListType: "small-event-card",
        tag: "durga-puja-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "go",
            slug: "durga-puja-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["ahmedabad"],
        cardListType: "small-event-card",
        tag: "navratri2024-ahmedabad",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-ahmedabad"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426050/frbom3wpgycrbylkzmf4.png",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727426101/zbuduv371rqjhms178wy.jpg",
        //categoryIcon: <Dhandia />,
        heading: "Non-Stop Navratri Fun",
        subheading: "",
        caption: "Nine days of celebrations and exciting offers. Get upto 1000 off*",
        cta: "Discover more",
        includeCities: ["surat"],
        cardListType: "small-event-card",
        tag: "navratri2024-surat",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "/navratri-2024-in-surat"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    // Type B
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437156/tegvlohjwrzfuftqo44o.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1723437160/yhdr4vaqmlnjjemq9ij1.jpg",
        categoryIcon: <Music />,
        heading: "PCRC LIVE!",
        subheading: "Enter the musical ‘Portrait Of A Time’ with Peter Cat Recording Co.",
        caption: "The band is coming to your city with their ‘Good Luck Beta’ tour! Brace yourselves for a mind-blowing performance and fresh new tracks!",
        cta: "Book Now",
        includeCities: ["goa"],
        cardListType: "schedule-card",
        tag: "good-luck-beta-tour",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "external",
            url: "microsite/pcrc-goodluckbeta24"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    //type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699990/fv1kl34fdfjfyicqeyw6.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699956/dquxcaxfazkog2fyznjr.jpg",
        categoryIcon: <Music />,
        heading: "Hip-hop Adhi LIVE!",
        subheading: "Experience HIPHOP TAMIZHA Live in Chennai!",
        caption: "Tamil folk arts seamlessly blended into high energy performances and music. Are you ready for an  unforgettable spectacle?",
        cta: "Buy Now",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "return-of-the-dragon-machi-home-edition-oct19-2024-chennai"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726284999/yjyuwel1lj6l9qsxs0ui.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1726285003/bhhsthkhycasjqh28wyn.jpg",
        categoryIcon: <Music />,
        heading: "Yuvan Shankar LIVE!",
        subheading: "Experience Yuvan Shankar Raja Live: The G.O.A.T. in Coimbatore!",
        caption: "Join us in Coimbatore for an unforgettable night with Yuvan Shankar Raja, the G.O.A.T. of music. Live performance, exceptional vibes!",
        cta: "Buy Now",
        includeCities: ["coimbatore"],
        link: {
            resource_type: "event",
            slug: "yuvan-shankar-raaja-the-goat-live-in-coimbatore-oct-12"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225442/hy0evrvrfjqtpjcst8na.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1724225439/fijvxhp4z4s7gsrtaeo2.jpg",
        categoryIcon: <Music />,
        heading: "First time in Vizag!",
        subheading: "Catch Armaan Malik LIVE this November!",
        caption: "Known for his soulful voice and chart-topping hits - from romantic ballads to foot-tapping numbers, experience the magic of his music up close and personal.",
        cta: "Buy Now",
        includeCities: ["visakhapatnam", "vizag"],
        link: {
            resource_type: "event",
            slug: "armaan-malik-live-in-vizag-nov9-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    }
];
